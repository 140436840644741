import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { ApiService } from '../api.service';
import { AuthService } from '../auth/auth.service';
import { SocketService } from '../socket.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  /**
	 * Notifier service
	 */
  loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });
  registerForm = new FormGroup({
    email: new FormControl(''),
    phoneNumber: new FormControl(''),
    company: new FormControl(''),
    password: new FormControl(''),
  });
  resetPinForm = new FormGroup({
    username: new FormControl(''),
  });
  changePinForm = new FormGroup({
    token: new FormControl(''),
    password: new FormControl(''),
  });
  learnMore = false;
  year = new Date().getFullYear();
  selectedServiceIndex = 0;
  form = 'login';
  autoplay = true;

  services = [
    {
      title: 'beamLive',
      description: 'Chat, Share and Collaborate with sub-second IoT connectivity using GPS location, live video & text.',
    },
    {
      title: 'beam2talk',
      description: 'Mimics the simplicity of Push-2-Talk over LTE and FirstNet with GPS and video augmenting audio-only, plus provides unlimited channels.',
    },
    {
      title: 'beamAssist',
      description: 'One-touch, 3-button app to instantly shares personal status, real-time location, video & vital data.',
    },
    {
      title: 'beamAlert',
      description: 'Unique 2-way alert and notification service targeting custom groups that allows users to respond with real-time, critical information.',
    },
    {
      title: 'beamGPS',
      description: 'Enhanced situational awareness with sub-second latency GPS connectivity overlaying live audio and video, sharing “When” and “Where” as needed.',
    },
    {
      title: 'beamAdmin',
      description: 'Intuitive admin Web portal to set-up and manage mobile workforce and assets and integrate IoT apps.',
    },
    {
      title: 'beamCAD',
      description: 'Intuitive mobile dispatching service designed to connect to legacy 911 CAD systems; Supports migration path to Broadband LTE and FirstNet.',
    },
    {
      title: 'beamAnalytics',
      description: 'Provides custom analytics to enhance response time and efficiencies based on type, time and location of call/incident.',
    },
  ]

  /**
	 * Constructor
   * @param {ApiService} api  Api service
   * @param {Router} router   Router
	 * @param {AuthService} auth Auth service
	 * @param {NotifierService} notifier Notifier service
	 */

  constructor(
    private readonly api: ApiService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly auth: AuthService,
    private readonly notifier: NotifierService,
    private readonly socket: SocketService,
    ) {
  }

  ngOnInit() {
    if (this.auth.isLoggedIn()) {
      this.router.navigate(['/people']);
    }
    
    this.route.queryParams.subscribe(params => {
      console.log(params['change-pin'])
      if (params['change-pin']) {
        this.form = 'change-pin';
      }
    })

    setInterval(() => {
      if (!this.autoplay) {
        return;
      }
      this.selectedServiceIndex = (this.selectedServiceIndex) === this.services.length ? 0 : this.selectedServiceIndex + 1;
    }, 10000);
  }

  login() {
    this.api.post('user/login', this.loginForm.value)
      .subscribe((res) => {
        const result = res.json();
        if (result && result.user) {
          this.auth.setToken(result.accessToken);
          this.auth.setUserRole(result.user.role);
          localStorage.setItem('user', JSON.stringify(result.user));
          this.socket.connect();
          this.notifier.notify('success', 'Logged in successfully');
          this.router.navigate(['/']);
        } else {
          this.notifier.notify('error', result.message);
        }
      }, err => {
        const res = err.json();
        this.notifier.notify('error', res.message);
      });
  }

  register() {
    this.api.post('user/register', this.registerForm.value)
      .subscribe((res) => {
        this.notifier.notify('success', 'You have registered successfully. Please login using your credentials.');
        this.form = 'login';
      }, err => {
        const res = err.json();
        this.notifier.notify('error', res.message);
      });
  }

  resetPin() {
    this.api.post('user/pin/reset', this.resetPinForm.value)
      .subscribe((res) => {
        this.notifier.notify('success', 'Check your email for the reset token.');
        this.form = 'change-pin';
      }, err => {
        const res = err.json();
        this.notifier.notify('error', res.message);
      });
  }

  changePin() {
    this.api.post('user/pin/change', this.changePinForm.value)
      .subscribe((res) => {
        this.notifier.notify('success', 'Your PIN has been successfully reset');
        this.form = 'login';
      }, err => {
        const res = err.json();
        this.notifier.notify('error', res.message);
      });
  }

}
