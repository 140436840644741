import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AuthGuard, Redirect, AdminGuard, ClientGuard } from './shared/auth/auth.guard';


export const AppRoutes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: FullComponent,

    children: [
      {
        path: '',
        canActivate: [AuthGuard, Redirect],
        pathMatch: 'full',
        loadChildren: './asset/asset.module#AssetModule',
      },
      {
        path: 'login',
        loadChildren: './shared/shared.module#SharedModule',
        pathMatch: 'full'
      },
      {
        path: 'account',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren: './account/account.module#AccountModule',
      },
      {
        path: 'organization',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren: './organization/organization.module#OrganizationModule',
      },
      {
        canActivate: [AuthGuard, ClientGuard],
        path: 'people',
        loadChildren: './people/people.module#PeopleModule'
      },
      {
        canActivate: [AuthGuard, ClientGuard],
        path: 'groups',
        loadChildren: './group/group.module#GroupModule'
      },
      {
        path: 'categories',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren: './category/category.module#CategoryModule'
      },
      {
        path: 'assets',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren: './asset/asset.module#AssetModule',
      },
      {
        path: 'devices',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren: './device/device.module#DeviceModule',
      },
      {
        path: 'analytics',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren: './analytics/analytics.module#AnalyticsModule'
      },
      {
        path: 'gps-live',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren: './gps-live/gps-live.module#GpsLiveModule',
      },
      {
        path: 'gps',
        canActivate: [AuthGuard],
        loadChildren: './gps/gps.module#GpsModule'
      },
      {
        path: 'iot-report',
        canActivate: [AuthGuard],
        loadChildren: './iot-report/iot-report.module#IotReportModule'
      },
      {
        path: 'assist',
        canActivate: [AuthGuard],
        loadChildren: './assist/assist.module#AssistModule'
      },
      {
        path: 'fleets',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren: './fleet/fleet.module#FleetModule'
      },
      {
        path: 'users',
        canActivate: [AuthGuard, AdminGuard],
        loadChildren: './user/user.module#UserModule'
      },
      {
        path: 'logs',
        canActivate: [AuthGuard],
        loadChildren: './logs/log.module#LogModule'
      },
      {
        path: 'iot-settings',
        canActivate: [AuthGuard],
        loadChildren: './iot-settings/iot-settings.module#IotSettingsModule'
      },
      {
        path: 'events',
        canActivate: [AuthGuard],
        loadChildren: './events/events.module#EventsModule'
      },
      {
        path: 'geofence',
        canActivate: [AuthGuard],
        loadChildren: './geofence/geofence.module#GeoFenceModule'
      }
    ]
  },
  {
    path: '**',
    loadChildren: './page-not-found/not-found.module#PageNotFoundModule'
  },
];
